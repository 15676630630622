import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import Breadcrumb, {Crumb} from "../../../components/common/Breadcrumbs";
import InputField from "../../../components/common/form/InputField";
import SelectField from "../../../components/common/form/SelectField";
import {useNavigate, useParams} from "react-router-dom";
import {useSearchCustomersQuery,} from "../customer/customerApi";
import {
    booleanChecks,
    createOptionListForSelectTag,
    createOptionTypeListFromArray,
    isNullOrEmpty
} from "../../../helpers/utils/Utils";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import {useGetRolesByCustomerIdQuery} from "../roles/rolesApi";
import Form from "../../../components/common/form/Form";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {
    useCreateUserMutation, useForgotPasswordMutation,
    useGetUserByIdQuery,
    useImpersonateUserMutation,
    useUpdateUserMutation
} from "./usersApi";
import {skipToken} from "@reduxjs/toolkit/query";
import MultiFreeFormField from "../../../components/common/form/MultiFreeFormField";
import {useGetBranchesForCustomerQuery} from "../customer/branchApi";
import {IoMdAdd} from "react-icons/io";
import SimplifileTokenModal from "./modal/SimplifileTokenModal";
import IconButton from "../../../components/common/form/IconButton";
import {FaArrowRightArrowLeft, FaLock} from "react-icons/fa6";
import ResetPassword from "../../../components/common/resetPassword/ResetPassword";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {Routes as AppRoutes} from "../../../routes";
import {FaEnvelope, FaStethoscope} from "react-icons/fa";
import AuditableInfoModal from "../../../components/common/AuditableInfoModal";
import ButtonTray from "../../../components/common/form/ButtonTray";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";

interface UserFieldProps {
    crumbs: Crumb[];
    title: string,
    btnText: string,
    isEditable?: any
}

const UserForm: React.FC<UserFieldProps> = ({crumbs, title, btnText, isEditable = true}) => {

    const navigate = useNavigate();
    let {userId} = useParams();

    const [userData, setUserData] = useState<any>({});
    const customersApi = useSearchCustomersQuery({
        pageSize: 10000
    });
    const userByIdApi = useGetUserByIdQuery(userId ?? skipToken);
    const branchesForCustomerApi = useGetBranchesForCustomerQuery({
        pageSize: 100,
        customerId: userData?.customer?.value
    }, {skip: isNullOrEmpty(userData?.customer)});
    const rolesByCustomerIdApi = useGetRolesByCustomerIdQuery(userData?.customer?.value, {skip: isNullOrEmpty(userData?.customer)});
    const [createUser, createUserApiResponse] = useCreateUserMutation();
    const [updateUser, updateUserApiResponse] = useUpdateUserMutation();
    const [impersonateUser, impersonateUserResponse] = useImpersonateUserMutation();
    const [forgotPasswordApi, forgotPasswordApiResponse] = useForgotPasswordMutation()


    useEffect(() => {
        let userObj = userByIdApi?.data;
        if (userObj) {
            setUserData({
                ...userObj,
                enabled: userObj.enabled ? booleanChecks[1] : booleanChecks[0],
                accountNonLocked: userObj.accountNonLocked ? booleanChecks[1] : booleanChecks[0],
                credentialsNonExpired: userObj.credentialsNonExpired ? booleanChecks[1] : booleanChecks[0],
                customer: userObj.customer ? {
                    value: userObj.customer?.id,
                    label: userObj.customer?.customerName
                } : null,
                branch: userObj.branch ? {value: userObj.branch?.id, label: userObj.branch?.name} : null,
                roles: createOptionTypeListFromArray(userObj.roles),
                teams: createOptionTypeListFromArray(userObj.teams),
            })
        }
    }, [userByIdApi]);

    useEffect(() => {
        if (impersonateUserResponse.isSuccess && !impersonateUserResponse.isLoading) {
            navigate("/home")
            window.location.reload();
        }
    }, [impersonateUserResponse]);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setUserData({
            ...userData,
            [name]: value,
            // If customer is de-selected it should empty the branch field too
            ...(name === "customer" ? {"branch": null, "roles": null} : {})
        })
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const commonObj = {
            username: userData.username,
            email: userData.email,
            customer: {id: userData?.customer.value},
            branch: {id: userData?.branch.value},
            roles: userData?.roles.map((el: any) => el.value),
        };

        const createOrUpdateUser = async () => {
            if (btnText === "Create") {
                await createUser({...commonObj, enabled: true}).unwrap();
            } else {
                await updateUser({
                    id: userId,
                    requestBody: {
                        ...commonObj,
                        enabled: userData?.enabled.value,
                        accountNonLocked: userData?.accountNonLocked.value,
                        credentialsNonExpired: userData?.credentialsNonExpired.value,
                    },
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateUser, () => {
            setUserData({});
            navigate("/access-control/users");
        });
    };

    const handleImpersonateUserSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const requestParams = {
            username: userData.username
        }

        await impersonateUser(requestParams);
        await handleRTKQuery(
            async () => {
                return await impersonateUser(requestParams);
            }
        )
    }

    useEffect(() => {
        const obj = branchesForCustomerApi?.data;
        if (obj && obj?.data && obj?.data?.length === 1) {
            setUserData({
                ...userData,
                branch: {value: obj?.data[0].id, label: obj?.data[0]?.name}
            })
        } else {
            setUserData({
                ...userData,
                branch: null
            })
        }
    }, [branchesForCustomerApi]);

    const handleSendResetPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (userData?.email) {
            return forgotPasswordApi(userData?.email);
        }
    }

    return (

        <div className="px-4 py-2">
            <div className={"justify-between sm:block md:flex lg:flex"}>

                <Breadcrumb crumbs={crumbs}/>

                {userId &&

                    <ButtonTray
                        buttons={[
                            {
                                buttonProps: {
                                    modal: <ConfirmationModal
                                        show={true}
                                        setShow={() => {
                                        }}
                                        title="Impersonate"
                                        bodyMessage="Are you sure you want to impersonate this user?"
                                        onSubmit={handleImpersonateUserSubmit}
                                        isLoading={impersonateUserResponse.isLoading}
                                    />,
                                    onOpen: () => {
                                    },
                                    triggerButton: <IconButton icon={<FaArrowRightArrowLeft/>}
                                                               label={"Impersonate User"}
                                                               title={"Impersonate User"}/>,
                                    isVisible: hasPermission(["IMPRUSR-V"])
                                },
                                buttonType: "modal"
                            },
                            {
                                buttonProps: {
                                    modal: <ConfirmationModal
                                        show={true}
                                        setShow={() => {
                                        }}
                                        title={"Send Reset Password"}
                                        bodyMessage={"Are you sure you want to send a reset password email?"}
                                        onSubmit={handleSendResetPasswordSubmit}
                                        isLoading={forgotPasswordApiResponse.isLoading}
                                    />,
                                    onOpen: () => {
                                    },
                                    triggerButton: <IconButton icon={<FaEnvelope/>} label={"Send Reset Password"}
                                                               title={"Send Reset Password"}/>,
                                    isVisible: hasPermission(["RESETPW-V"])
                                },
                                buttonType: "modal"
                            },
                            {
                                buttonProps: {
                                    modal: <ResetPassword
                                        show={true}
                                        setShow={() => {
                                        }}
                                        page={"User"}
                                    />,
                                    onOpen: () => {
                                    },
                                    triggerButton: <IconButton icon={<FaLock/>} label={"Reset Password"}
                                                               title={"Reset Password"}/>,
                                    isVisible: hasPermission(["RESPASS-V"])
                                },
                                buttonType: "modal"
                            },
                            {
                                buttonProps: {
                                    modal: <SimplifileTokenModal
                                        show={true}
                                        setShow={() => {
                                        }}
                                    />,
                                    onOpen: () => {
                                    },
                                    triggerButton: <IconButton icon={<IoMdAdd/>} label={"Simplifile Token"}
                                                               title={"Add Simplifile Token"}/>,
                                    isVisible: hasPermission(["USR-U"])
                                },
                                buttonType: "modal",
                            },
                            {
                                buttonProps: {
                                    modal: <AuditableInfoModal
                                        show={true}
                                        setShow={() => {
                                        }}
                                        createdBy={userData?.createdBy}
                                        creationDate={userData?.creationDate}
                                        modifiedBy={userData?.modifiedBy}
                                        modifiedDate={userData?.modifiedDate}
                                    />,
                                    onOpen: () => {
                                    },
                                    triggerButton: <IconButton icon={<FaStethoscope/>}
                                                               title={"Auditable Info"} hasBackground={true}/>,
                                    isVisible: userData && hasPermission(["AUDIT-V"])
                                },
                                buttonType: "modal",
                            }
                        ]}
                        align={"end"}
                        gap={3}
                    />

                }

            </div>
            <div className="add_user_outer flex justify-center mt-3">
                <div className="adduser_content bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                    <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">{title}</h3>
                    <Form onSubmit={handleSubmit} fields={<>
                        <div className="grid grid-cols-12 adduser_Wrapper">

                            <InputField
                                name={"username"}
                                label="Name"
                                onChange={handleOnChange}
                                value={userData?.username || ""}
                                type="text"
                                placeholder="Enter Name..."
                                required={true}
                                disabled={userId !== undefined}
                            />

                            <InputField
                                label="Email"
                                name={"email"}
                                onChange={handleOnChange}
                                value={userData?.email || ""}
                                type="email"
                                placeholder="Enter Email..."
                                required={true}
                                className={"mt-3"}
                            />

                            <SelectField
                                placeholder='Select Customer...'
                                value={userData?.customer || null}
                                options={createOptionListForSelectTag(customersApi?.data?.data, "customerName", "id", "customerNumber")}
                                onChange={(selectedOption) => {
                                    handleOnChange({name: "customer", value: selectedOption})
                                }}
                                required={true}
                                isLoading={customersApi.isLoading}
                                label={"Customer"}
                            />

                            <SelectField
                                placeholder='Select Branch...'
                                options={createOptionListForSelectTag(branchesForCustomerApi?.data?.data, "name", "id")}
                                value={userData?.branch || null}
                                onChange={(selectedOption) => {
                                    handleOnChange({name: "branch", value: selectedOption})
                                }}
                                required={true}
                                isLoading={isNullOrEmpty(userData.customer)}
                                label={"Branch"}
                            />

                            <SelectField
                                placeholder='Select Roles...'
                                options={createOptionListForSelectTag(rolesByCustomerIdApi.data, "name", "name")}
                                value={userData?.roles || null}
                                onChange={(selectedOption) => {
                                    handleOnChange({name: "roles", value: selectedOption})
                                }}
                                required={true}
                                isLoading={isNullOrEmpty(userData.customer)}
                                label={"Roles"}
                                isMulti={true}
                            />


                            {
                                btnText === "Update" &&
                                <>
                                    <MultiFreeFormField
                                        defaultOptions={userData?.teams}
                                        placeholder={"Enter Teams..."}
                                        onChange={() => {
                                        }}
                                        name={"teams"}
                                        label={"Teams"}
                                        disabled={true}
                                    />

                                    <SelectField
                                        placeholder='Select Status...'
                                        options={booleanChecks}
                                        value={userData?.enabled || null}
                                        onChange={(selectedOption) => {
                                            handleOnChange({name: "enabled", value: selectedOption})
                                        }}
                                        required={true}
                                        isLoading={false}
                                        label={"Status"}
                                    />


                                    <SelectField
                                        placeholder='Select Unlocked...'
                                        options={booleanChecks}
                                        value={userData?.accountNonLocked || null}
                                        onChange={(selectedOption) => {
                                            handleOnChange({name: "accountNonLocked", value: selectedOption})
                                        }}
                                        required={true}
                                        isLoading={false}
                                        label={"Unlocked"}
                                    />

                                    <SelectField
                                        placeholder='Select Password Unexpired...'
                                        options={booleanChecks}
                                        value={userData?.credentialsNonExpired || null}
                                        onChange={(selectedOption) => {
                                            handleOnChange({name: "credentialsNonExpired", value: selectedOption})
                                        }}
                                        required={true}
                                        isLoading={false}
                                        label={"Password Unexpired"}
                                    />

                                </>
                            }


                        </div>

                        <ButtonTray
                            buttons={[
                                {
                                    buttonProps: {
                                        btnText: "Cancel",
                                        onClick: () => navigate(AppRoutes.Users.path),
                                        type: "close",
                                    },
                                    buttonType: "button"
                                },
                                {
                                    buttonProps: {
                                        btnText: "Submit",
                                        type: "submit",
                                        isLoading: (createUserApiResponse.isLoading || updateUserApiResponse.isLoading),
                                        isVisible: isEditable
                                    },
                                    buttonType: "button",

                                }
                            ]}
                            align="end"
                            gap={2}
                        />

                    </>}/>


                </div>
            </div>

        </div>
    );
};
export default UserForm;
